<template>
  <div>
    <div class="search">
      <el-row :gutter="20">
        <el-col :span="6">
          客户名称<el-input
            v-model="customerNo"
            placeholder="请输入客户名称"
          ></el-input>
        </el-col>
        <el-col :span="6">
          订单编号<el-input
            v-model="orderNo"
            placeholder="请输入订单编号"
          ></el-input>
        </el-col>
        <el-col :span="6">
          项目编号或名称<el-input
            v-model="projectNo"
            placeholder="请输入项目编号或名称"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <div>订单状态</div>
          <el-select v-model="status" placeholder="请选择订单状态">
            <el-option value="" label="全部"></el-option>
            <el-option
              v-for="item in statuses"
              :key="item"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <div class="search_buttons">
        <el-button type="primary" v-if="addflag && auth['order:new']" icon="el-icon-plus" @click="handler_add()">添加</el-button>
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="handler_search()"
          >搜索
        </el-button>
      </div>
    </div>
    <el-table
      :data="page.list"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      border
      width="width:100%; height:50%"
    >
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column
        prop="orderno"
        label="订单编号"
        width="200"
      ></el-table-column>
      <el-table-column prop="customerName" label="客户"></el-table-column>
      <el-table-column
        prop="toDept.name"
        label="所属部门"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="toWorker.name"
        label="负责人"
        width="150"
      ></el-table-column>

      <el-table-column
        prop="status"
        label="状态"
        width="150"
        :formatter="formatterStatus"
      ></el-table-column>
      <el-table-column prop="scope" label="操作" width="150">
        <template #default="scope">
          <el-button
            type="text"
            icon="el-icon-view"
            @click="handler_view(scope.row)"
            v-if="auth['order:get']"
            >查看
          </el-button>
          <el-button
            type="text"
            v-if="scope.row.status == 0 && auth['order:delete']"
            icon="el-icon-delete"
            class="red"
            @click="handler_delete(scope.row)"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="devlogChange"
      :current-page="page.pageNum"
      :page-size="page.pageSize"
      layout="total, prev, pager, next, jumper"
      :total="page.total"
    >
    </el-pagination>
  </div>
</template>

<script>
import ORDER from "../../api/biz/api_order";
import OrderStatus from "./order_status";
import BreakStatus from "./breakflag_status";
import RebuildingStatus from "./rebuildingflag_status";

import syscomm from "../../utils/syscomm";
import COMM from "../../api/base/api_comm";

export default {
  name: "orderlist",
  props: {
    customerInfo: {
      type: Object
    },
    addflag: {
      type: Boolean,
      default: true
    },
    propsStatus: {
      type: Number
    }
  },
  data() {
    return {
      showLoading: false,
      customerNo: undefined,
      orderNo: undefined,
      projectNo: undefined,
      status: undefined,
      statuses: [],
      page: { list: [], pageNum: 1, pageSize: 10, total: 0 },
      auth:{},
    };
  },
  // 初始化加载
  mounted() {
    this.init();
  },
  
  // 方法定义
  methods: {
    init() {
      this.initPageData();
      this.auth=this.$getAuth();
      console.log("order list auth "+JSON.stringify(this.auth))
    },

    // 加载页面数据
    initPageData() {
      this.showLoading = true;
      this.statuses = OrderStatus.statuses;

      let statuscur = null;
      if (this.status == undefined) {
        statuscur = this.propsStatus;
      } else {
        statuscur = this.status;
      }

      ORDER.list({
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        customerNo: this.customerNo,
        orderNo: this.orderNo,
        projectNo: this.projectNo,
        status: statuscur,
      }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          var data = res.pageData;
          this.page.total = data.total;
          this.page.list = data.list;
        } else {
          this.$message.error(res.msg);
          this.page.list = [];
        }
      });
    },
    devlogChange(pageNum) {
      this.page.pageNum = pageNum;
      this.initPageData();
    },

    //create project
    handler_add() {
      let customerid = null;
      if (this.customerInfo != null) {
        customerid = this.customerInfo.id
      }
      this.$router.push({ path: '../createOrder', query: { customerId: customerid } });
    },

    handler_search() {
      this.initPageData();
    },
    formatterStatus(row, index) {
      let val = row?.status;
      return OrderStatus.getLabelByValue(val);
    },
    handler_view(row) {
      this.$router.push({
        path: "../orderView",
        query: {
          orderId: row?.id,
        },
      });
    },
    handler_delete(row) {
      this.$confirm("此操作将删除订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = { id: row.id };
          ORDER.delete(params)
            .then((res) => {
              if (200 == res.code) {
                this.$message.success("操作成功！");
                this.init();
              } else {
                this.$message.error(res?.msg);
              }
            })
            .catch(() => console.error("promise catch err"));
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.search {
  margin-bottom: 10px;
}
.search_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.search_buttons input {
  margin-right: 20px;
}
.search_buttons input:last-child {
  margin-right: 0;
}
</style>