/**
 * 损坏标记（ 1： 损坏； 0： 未损坏）
 */

export default {
    statuses: [{
            value: 0,
            label: "未损坏"
        },
        {
            value: 1,
            label: "损坏"
        },
    ],
    getLabelByValue: (value) => {
        switch (value) {
            case 0:
                return "未损坏";
            case 1:
                return "损坏";
            default:
                return "未知";
        }
    },
}