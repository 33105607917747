/**
 * 改造标记（ 1： 改造； 0： 不改造）
 */

export default {
    statuses: [{
        value: 0,
        label: "不改造"
    }, {
        value: 1,
        label: "改造"
    }],
    getLabelByValue : (value) => {
        switch (value) {
            case 0:
                return "不改造";
            case 1:
                return "改造";
            default:
                return "未知";
        }
    }
}